<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-office-building-marker"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Empresas</div>
          </template>

          <v-btn
            id="btn-nova-empresa"
            v-if="tipoAutenticacao === 'database' && accessReleased('EMPRESAS_ADICIONAR')" 
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('edit')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Nova Empresa
          </v-btn>

          <v-text-field
            id="empresas-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-empresa"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="empresa-table"
            :headers="[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Empresa',
                value: 'name'
              },
              {
                text: 'Sigla',
                value: 'acronym'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="empresas"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.status`]="{ item }">
              <empresas-status :status="item.ativa" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                id="action-edit-empresa"
                v-if="
                  tipoAutenticacao === 'database' &&
                  accessReleased('EMPRESAS_EDITAR')
                "
                :class="!canEditItem(item) ? 'cursor-block' : ''"
                :disabled="!canEditItem(item)"
                color="gray"
                min-width="0"
                class="px-1 ml-n1"
                fab
                icon
                x-small
                @click="$router.push(`edit/${item.id}`)"
              >
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
              <v-menu
                offset-y
                v-if="tipoAutenticacao === 'database' || usuarioAdmin"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="action-more-empresa"
                    color="white"
                    class="ml-1"
                    height="22px"
                    width="22px"
                    fab
                    x-small
                    elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="canChangeStatus(item)"
                    @click="openDialogChangeStatus(item)"
                  >
                    <v-list-item-title>
                      <v-icon
                        small
                        v-if="item.ativa === 'Ativa'"
                      >
                        mdi-office-building-remove-outline
                      </v-icon>
                      <v-icon v-else> mdi-office-building-plus-outline </v-icon>
                      <span v-if="item.ativa === 'Ativa'"> Inativar </span>
                      <span v-else> Ativar </span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="usuarioAdmin"
                    @click="
                      $router.push('entidades-bdgd-obrigatorias/' + item.id)
                    "
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-database-cog-outline </v-icon>
                      Entidades BDGD
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="usuarioAdmin"
                    @click="
                      $router.push({
                        name: 'Extensão de Rede',
                        params: { id: item.id }
                      })
                    "
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-map-marker-path </v-icon>
                      Extensão de Rede
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="usuarioAdmin"
                    @click="$router.push('extensao-geografica/' + item.id)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-vector-square-edit </v-icon>
                      Extensão Geográfica
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="usuarioAdmin"
                    @click="$router.push('agentes/' + item.id)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-account-hard-hat </v-icon>
                      Códigos da Distribuidora
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="usuarioAdmin"
                    @click="$router.push('periodos-incrementais/' + item.id)"
                  >
                    <v-list-item-title>
                      <v-icon small> mdi-calendar-blank </v-icon>
                      Período Incremental
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div v-else>--</div>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getEmpresas()"
                    >mdi-refresh</v-icon
                  >
                </template>
                <span>Clique aqui para recarregar as empresas</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <dialog-change-status
            :dialog="dialogChangeStatus"
            @update:dialog="dialogChangeStatus = $event"
            @submitTriggered="changeCompanyStatus(dialogChangeStatusData.id)"
          >
            <template slot="title">
              <span v-if="dialogChangeStatusData.ativa === 'Ativa'">
                Inativar
              </span>
              <span v-else> Ativar </span> empresa #{{
                dialogChangeStatusData.id
              }}
            </template>
            <template slot="body">
              Tem certeza que deseja
              <span v-if="dialogChangeStatusData.ativa === 'Ativa'">
                inativar
              </span>
              <span v-else> ativar </span>
              a empresa <strong>{{ dialogChangeStatusData.name }}</strong
              >?
            </template>
          </dialog-change-status>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import CompanyService from '@/services/CompanyService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'EmpresasListar',

  mixins: [refreshDataMixins, profilePermissionsMixin],

  components: {
    EmpresasStatus: () => import('@/components/general/EmpresasStatus.vue'),
    DialogChangeStatus: () => import('@/components/general/DialogChangeStatus')
  },

  data() {
    return {
      empresas: [],
      usuarioAdmin: false,
      tipoAutenticacao: null,
      search: '',
      dialogChangeStatus: false,
      dialogChangeStatusData: {
        id: undefined,
        name: undefined
      },
      loading: false
    };
  },

  mounted() {
    this.refreshData(this.getEmpresas);
    this.getUsuarioAdmin();
    this.getTipoAutenticacao();
  },

  computed: {
    usuario() {
      return this.$store.getters.getUser;
    }
  },

  methods: {
    getEmpresas() {
      this.empresas = [];
      this.loading = true;

      CompanyService.getAll()
        .then(({ data }) => {
          this.empresas = data.data;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Erro ao recuperar as empresas.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    async getUsuarioAdmin() {
      this.usuarioAdmin = await AuthService.userIsAdmin();
    },

    async getTipoAutenticacao() {
      this.tipoAutenticacao = await AuthService.getTipoAutenticacao();
    },

    canEditItem() {
      return this.tipoAutenticacao === 'database' && this.usuarioAdmin;
    },

    canChangeStatus() {
      return this.tipoAutenticacao === 'database' && this.usuarioAdmin;
    },

    changeCompanyStatus(id) {
      CompanyService.changeStatus(id)
        .then(() => {
          this.$toast.success('Status da empresa atualizada com sucesso.', '', {
            position: 'topRight'
          });
          this.getEmpresas();
        })
        .catch((error) => {
          this.$toast.error('Erro ao atualizar o status da empresa.', '', {
            position: 'topRight'
          });
          console.error(error);
        });

      this.dialogChangeStatus = false;
    },

    openDialogChangeStatus(empresa) {
      this.dialogChangeStatusData = empresa;
      this.dialogChangeStatus = true;
    }
  }
};
</script>
